<template>
    <div class="keyword">
        <div class="table_box">
            <el-table
                :data="hotData[curren - 1]"
                size="small"
                style="width: 100%"
                height="100%"
            >
                <el-table-column label="热点词">
                    <template slot-scope="scope">
                        <span>{{ scope.row.sentence }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="热度" width="180">
                    <template slot-scope="scope">
                        <span>{{ scope.row.hot_level }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="更新时间" width="180">
                    <template>
                        <span>{{ upTimer }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="相关视频" width="180">
                    <template slot-scope="scope">
                        <el-button size="mini" @click="checkVideo(scope.row)"
                            >查看</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="page_box">
            <el-pagination
                background
                small
                layout="total,prev, pager, next"
                :total="hotList.length"
                :current-page.sync="curren"
            >
            </el-pagination>
        </div>
    </div>
</template>

<script>
import { getDouHotKey, getDouHotKeyVideo } from "@/api/keyword";
export default {
    data() {
        return {
            hotList: [],
            upTimer: "",
            hotData: [],
            curren: 1,
        };
    },
    created() {
        getDouHotKey().then((res) => {
            if (res.code !== 200) return this.$message.error("获取热点词失败");
            let group = [];
            res.data.list.map((item, index) => {
                group.push(item);
                if (group.length === 10) {
                    let page = JSON.stringify(group);
                    this.hotData.push(JSON.parse(page));
                    group = [];
                }
            });
            this.hotList = res.data.list;
            this.upTimer = res.data.active_time;
        });
    },
    methods: {
        async checkVideo(data) {
            const loading = this.$loading({
                lock: true,
                text: "正在获取视频链接",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let res = await getDouHotKeyVideo({ hotSentence: data.sentence });
            loading.close();
            if (res.data.list.length) {
                window.open(res.data.list[0].share_url);
            }
            console.log(res);
        },
    },
};
</script>


<style lang="less" scoped>
.keyword {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .table_box {
        flex: 1;
    }
    .page_box {
        margin-top: 10px;
        background-color: #fff;
        padding: 6px 0;
    }
}
</style>